<template>
  <div class="pb-0">
    <div
      id="custom-message-header"
      class="container-fluid custom-message-header"
      :class="getBgCustomMessage()"
      v-if="settings.customMessage"
    >
      <div class="row no-gutters">
        <div
          class="col text-center text-uppercase py-1 font-weight-bold"
          style="font-size: 0.8rem"
        >
          {{ settings.customMessage }}
        </div>
      </div>
    </div>
    <div
      id="pre-header"
      class="container-fluid bg-dark text-white mini-header px-2"
    >
      <div class="container px-0" style="max-width: 1300px">
        <div class="row py-1 align-items-center">
          <div
            class="col-12 col-md text-uppercase"
            v-if="settings.style == 'delivery'"
          >
            <b-badge
              class="mr-1 my-1 text-dark"
              id="badge-schedule"
              variant="light"
              :variant1="status() == 'Online' ? 'success' : 'danger'"
              ><span
                class="badge-notif d-inline-block mr-1"
                :class="{
                  'bg-danger': status() == 'Offline',
                  'bg-success': status() == 'Online',
                }"
              ></span
              >{{ status() == "Online" ? "Abierto" : "Cerrado" }}</b-badge
            >
            <b-badge class="mr-1 click" v-if="today()" @click="showSchedule()">
              <span>{{ today() }}</span></b-badge
            >
            <b-badge
              class="mr-1"
              id="badge-pickup"
              v-if="settings.showPickup"
              :variant="settings.pickupEnabled ? 'success' : 'danger'"
            >
              <b-icon
                icon="x-circle-fill"
                class="text-white"
                v-if="!settings.pickupEnabled"
              />
              Retiro
              <span v-if="settings.pickupWaitTime && settings.pickupEnabled"
                >({{ settings.pickupWaitTime }} min)</span
              ></b-badge
            >
            <b-badge
              id="badge-delivery"
              class="mr-1"
              v-if="settings.showDelivery"
              :variant="settings.deliveryEnabled ? 'success' : 'danger'"
            >
              <b-icon
                icon="x-circle-fill"
                class="text-white"
                v-if="!settings.deliveryEnabled"
              />
              Reparto
              <span v-if="settings.deliveryWaitTime && settings.deliveryEnabled"
                >({{ settings.deliveryWaitTime }} min)</span
              ></b-badge
            >

            <b-badge
              class="mr-1 d-none"
              v-if="settings.waitTime"
              variant="dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-alarm"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"
                />
                <path
                  d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"
                />
              </svg>
              <span class="ml-1 d-inline-block text-break">{{
                getWaitTime(settings.waitTime)
              }}</span></b-badge
            >

            <b-badge
              class="mr-1 h-100"
              v-if="settings.minDelivery"
              id="badge-min-delivery"
              variant="info"
              >Reparto desde ${{ formatNumber(settings.minDelivery) }}</b-badge
            >
          </div>
          <div class="ml-auto col-3 col-sm-2 col-md-1">
            <div class="row">
              <div class="col-2" v-if="settings.facebook">
                <a
                  :href="`https://facebook.com/${settings.facebook}`"
                  target="_blank"
                  ><b-icon icon="facebook" class="mr-3"></b-icon
                ></a>
              </div>
              <div class="col-2" v-if="settings.instagram">
                <a
                  :href="`https://instagram.com/${settings.instagram}`"
                  target="_blank"
                >
                  <b-icon icon="instagram" class="mr-3"></b-icon
                ></a>
              </div>
            </div>
          </div>
          <div class="col-4 col-md-2 text-center d-none1">
            <b-dropdown
              id="dropdown-form"
              variant="dark"
              text="CUENTA"
              block
              right
              class="text-white text-uppercase"
              ref="dropdown"
              size="sm"
            >
              <div v-if="!isLogged()" style="width: 240px">
                <b-dropdown-form>
                  <b-form-group
                    label="Correo"
                    label-for="dropdown-form-email"
                    @submit.stop.prevent
                  >
                    <b-form-input
                      id="dropdown-form-email"
                      size="sm"
                      v-model="email"
                      placeholder="email@correo.com"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Contraseña"
                    label-for="dropdown-form-password"
                  >
                    <b-form-input
                      id="dropdown-form-password"
                      type="password"
                      size="sm"
                      v-model="password"
                      placeholder="*******"
                      @keyup.enter="login()"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-checkbox class="mb-3 d-none"
                    >Remember me</b-form-checkbox
                  >
                  <b-button
                    variant="primary"
                    block
                    size="sm"
                    @click="login()"
                    :disabled="loading || password == ''"
                    >INGRESAR</b-button
                  >
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button
                  class="d-none1"
                  @click="showModalCreate()"
                  >No tienes cuenta?
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="showModalRecover()"
                  >Enviame mi contraseña</b-dropdown-item-button
                >
              </div>
              <div v-else>
                <b-dropdown-item-button
                  @click="$router.push(getLink('cuenta'))"
                >
                  Mis pedidos
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="logout()">
                  Cerrar session
                </b-dropdown-item-button>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid message-offline w-100 overflow-hidden">
      <div class="container">
        <div class="fixed-bottom" v-if="status() == 'Offline' && appInit">
          <div
            class="alert alert-danger font-weight-bold text-center mb-0 rounded-0"
          >
            <div class="row align-items-center no-gutters">
              <div class="col px-4 text-uppercase">
                {{ info.custom_name || info.name }} no esta disponible
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container-fluid header overflow-hidden position-relative"
      style="z-index: 2"
      :class="getTheme()"
    >
      <img
        src=""
        id="img-header"
        style="opacity: 0.1; top: -100%; left: 0; width: 100%; height: 350px"
        class="position-absolute"
      />
      <div
        id="header"
        class="row py-3 align-items-center justify-items-center"
        style="max-width: 1300px; margin: 0 auto"
      >
        <div class="col-12" v-if="showHeader">
          <div
            class="row no-gutters align-items-center"
            :class="getThemeText()"
          >
            <!-- <div class="col-2 text-center d-none">
              <img
                :src="getLogo(info.logo)"
                class="img-fluid"
                style="max-height: 80px"
              />
            </div> -->

            <b-navbar
              :variant1="settings.headerColor"
              type="light"
              class="w-100 header-navbar"
              toggleable="md"
            >
              <b-navbar-brand>
                <div class="w-75">
                  <div class="row">
                    <div class="col-3 col-md-4 click" @click="goHome()">
                      <img
                        :src="getLogo(info.logo)"
                        class="img-fluid1"
                        id="logo"
                        style="max-height: 50px; max-width: 120px !important"
                      />
                    </div>
                    <div class="col-8 pl-5" id="name">
                      <span
                        style="font-size: 1.1em"
                        :class="getThemeText()"
                        class="d-none d-sm-inline-block font-weight-bold text-uppercase"
                      >
                        {{ info.custom_name || info.name }}
                      </span>
                      <div class="font-italic" v-if="todayRaw() && false">
                        <small class="d-block">
                          abierto
                          {{ $moment(todayRaw().openAt, "HH:mm:ss").fromNow() }}
                        </small>
                        <small class="d-block">
                          cierra
                          {{
                            $moment(todayRaw().closeAt, "HH:mm:ss").fromNow()
                          }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </b-navbar-brand>
              <b-navbar-toggle target="navbar-toggle-collapse">
                <template #default="{ expanded }">
                  <b-icon
                    v-if="expanded"
                    :class="getThemeText()"
                    icon="card-list"
                  ></b-icon>
                  <b-icon v-else :class="getThemeText()" icon="list"></b-icon>
                </template>
              </b-navbar-toggle>

              <b-collapse id="navbar-toggle-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                  <div
                    class="nav-item"
                    v-for="page in info.pages"
                    :key="page._id"
                  >
                    <router-link
                      class="nav-link text-uppercase h-100 align-items-center d-flex"
                      :exact="page.slug == ''"
                      active-class="font-weight-bold"
                      style="
                        border-bottom-width: 3px !important;
                        font-size: 0.8em;
                      "
                      :to="getLink(page.slug)"
                      ><b-icon
                        v-if="page.icon"
                        style="font-size: 1em !important"
                        :icon="page.icon"
                        class="mr-1"
                      >
                      </b-icon>
                      <!-- <div class="w-100"></div> -->
                      <span class="mx-2">{{ page.title }}</span>

                      <span
                        v-if="page.slug == 'pedido'"
                        class="badge badge-danger px-2"
                        >${{ formatNumber(total) }}</span
                      >
                    </router-link>
                  </div>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
        <div class="w-100 mt-3"></div>
      </div>
    </div>
    <div class="" id="content" style="min-height: 60vh" :class="getThemeBody()">
      <router-view class="animate__animated animate__fadeIn"></router-view>
    </div>

    <div
      class="overflow-hidden"
      v-if="settings.whatsapp"
      style="
        border-radius: 1500px;
        position: fixed;
        bottom: 130px;
        right: 30px;
        z-index: 99;
      "
    >
      <button
        id="wspbtn"
        class="btn bg-success text-white px-1"
        style="width: 35px; height: 35px"
        @click="goWhatsApp()"
      >
        <b-icon icon="whatsapp"></b-icon>
      </button>
      <b-tooltip
        target="wspbtn"
        placement="left"
        triggers="hover"
        :show1.sync="show"
        style="z-index: 2"
      >
        Hablar al WhatsApp
      </b-tooltip>
    </div>

    <div
      class="overflow-hidden rounded-circle"
      style="position: fixed; bottom: 80px; right: 30px; z-index: 99"
    >
      <button
        class="btn bg-primary text-white px-1"
        style="width: 35px; height: 35px"
        @click="goUp()"
      >
        <b-icon icon="arrow-up"></b-icon>
      </button>
    </div>

    <div class="py-5 mt-0 footer" id="footer" :class="getThemeFooter()" v-if="showFooter">
      <div class="w-100 py-0"></div>
      <div class="container">
        <div class="row">
          <div class="col-2 d-none">
            <img :src="getLogo(info.logo)" class="img-fluid w-50" alt="" />
          </div>
          <div class="w-100"></div>
          <div class="col text-center">
            <div class="font-weight-bold" id="footer-name">
              {{ info.custom_name || info.name }} &copy;
              {{ $moment().format("YYYY") }}
            </div>
            <small class="click">{{ info.address }} - {{ info.commune }}</small>
            <small class="text-muted click ml-2" @click="openAddres()">
              <b-icon icon="pin-map-fill"></b-icon>
            </small>
            <div class="w-100"></div>
            <small>{{ info.phone }}</small>
          </div>
        </div>
        <div class="row my-3 text-white">
          <div class="col col-md-12 ml-auto text-center">
            <router-link :to="getLink('tos')"
              >TERMINOS Y CONDICIONES</router-link
            >
          </div>

          <div class="w-100 py-3"></div>
          <div class="col-12 col-md-12 ml-auto text-center">
            <small class="text-muted">
              Sitio web por
              <a href="http://ticketapp.cl" target="_blank" class="link"
                >TicketApp</a
              >
            </small>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="schedule-modal" title="Horario" hide-footer>
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="day in schedule()"
              :key="day.n"
              :class="{
                active: day.n == $moment().format('d'),
              }"
            >
              <div class="row align-items-center">
                <div class="col-5">
                  <strong>{{ day.name }}</strong>
                </div>
                <div class="col" v-if="day.openAt && day.closeAt">
                  {{ $moment(day.openAt, "HH:mm:ss").format("HH:mm") }} -
                  {{ $moment(day.closeAt, "HH:mm:ss").format("HH:mm") }}
                  <div class="font-italic">
                    <small
                      class="d-block"
                      v-if="day.n == $moment().format('d')"
                    >
                      <span>{{
                        $moment(day.openAt, "HH:mm:ss").diff(
                          $moment(),
                          "minutes"
                        ) > 0
                          ? "Abre"
                          : "Abierto"
                      }}</span>
                      {{ $moment(day.openAt, "HH:mm:ss").fromNow() }}
                    </small>
                    <small
                      class="d-block"
                      v-if="day.n == $moment().format('d') && diffDay(day) < 0"
                    >
                      cierra {{ $moment(day.closeAt, "HH:mm:ss").fromNow() }}
                    </small>
                  </div>
                </div>
                <div class="col" v-else>CERRADO</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modal-create"
      size="lg"
      title="Crear cuenta"
      @ok="createAccount()"
    >
      <div class="d-none1">
        <div class="row">
          <div class="col-12">
            <strong>NOMBRE</strong>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              v-model="clientName"
              :class="{
                'is-invalid': clientName.length < 4,
                'is-valid': clientName.length >= 4,
              }"
            />
            <small class="text-muted"
              >Tu nombre y apellido. Ej: Juan Segura</small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <strong>CORREO</strong>
          </div>
          <div class="col">
            <input
              type="email"
              autocapitalize="off"
              class="form-control"
              v-model="email"
              :class="{
                'is-invalid': emailIsValid,
                'is-valid': !emailIsValid,
              }"
            />
            <small class="text-muted">Ej: tu@gmail.com</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <strong>CONTRASEÑA</strong>
          </div>
          <div class="col">
            <input
              type="password"
              class="form-control"
              v-model="password"
              :class="{
                'is-invalid': password.length < 6,
                'is-valid': password.length >= 6,
              }"
            />
            <small class="text-muted">Minimo 6 caracteres</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <strong>REPITA CONTRASEÑA</strong>
          </div>
          <div class="col">
            <input
              type="password"
              class="form-control"
              v-model="repassword"
              :class="{
                'is-invalid': password.length < 6 || password != repassword,
                'is-valid': password.length >= 6,
              }"
            />
            <small class="text-muted">Minimo 6 caracteres</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <strong>TELEFONO</strong>
          </div>
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">+56</span>
              </div>
              <input
                type="phone"
                class="form-control"
                v-model.number="phone"
                :class="{
                  'is-invalid': phone.toString().length != 9,
                  'is-valid': phone.toString().length == 9,
                }"
              />
            </div>
            <small class="text-muted">Ej: 987654321</small>
          </div>
          <div class="col-12 mt-4">
            <div class="alert alert-info">
              <small
                >AL ACEPTAR ACEPTAR HABER LEIDO LOS
                <router-link class="font-weight-bold" :to="getLink('tos')"
                  >TERMINOS Y CONDICIONES</router-link
                >.
                <br />
                CREARAS UNA CUENTA PARA TODAS LAS COMPRAS QUE REALICES EN LOS
                COMERCIOS ASOCIADOS A TICKETAPP.CL</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none">
        <div class="col">
          <h3>1. Debes tener pedidos realizados con anterioridad</h3>
        </div>
      </div>
    </b-modal>

    <b-modal
      :body-class="getThemeBody()"
      :header-class="getThemeBody()"
      ref="modal"
      :title="productName"
      title-class="text-uppercase"
      size="lg"
      id="modal-product"
      scrollable
      hide-footer
      @hide="closeModal()"
    >
      <product
        :id="pid"
        @update="updateProductData"
        @success="closeModal()"
        :textColor="getThemeText()"
      />
    </b-modal>

    <b-modal ref="modal-recover" title="Recuperar cuenta" @ok="sendRecover()">
      <div class="row">
        <div class="col-12">
          <strong>RECUPERAR CONTRASEÑA</strong>
        </div>
        <div class="col">
          <input
            type="email"
            class="form-control"
            v-model="email"
            :class="{
              'is-invalid': emailIsValid,
              'is-valid': !emailIsValid,
            }"
          />
          <small class="text-muted">Ingreso tu correo</small>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import Product from "./pages/Product.vue";
const Product = () => import("./pages/Product.vue");
export default {
  components: {
    Product,
  },
  name: "App",
  data() {
    return {
      attempt: 0,
      pid: null,
      productName: "",
      timeout: null,
      showUp: false,
      lastScroll: 0,
      showUpButton: true,
      showHeader: true,
      showFooter: true,
      nstatus: false,
      email: "",
      password: "",
      repassword: "",
      appInit: false,
      show: false,
      clientName: "",
      phone: "",
      loading: false,
    };
  },
  mounted() {
    // redirect if url start with www
    if (document.location.href.indexOf("www.") != -1) {
      console.log("contiene www.");
      document.location.href = document.location.href.replace("www.", "");
    }

    if (this.$route.query.header == 0) this.showHeader = false;
    if (this.$route.query.footer == 0) this.showFooter = false;
    this.load();

    if (this.$cookies.get("clientName"))
      this.clientName = this.$cookies.get("clientName");
    if (this.$cookies.get("phone")) this.phone = this.$cookies.get("phone");
    if (this.$cookies.get("email")) this.email = this.$cookies.get("email");

    // window.addEventListener("scroll", () => {
    //   if (window.scrollY > 300) this.showUpButton = true;
    //   if (window.scrollY > 300 && this.lastScroll > window.scrollY) {
    //     this.showUp = true;
    //   } else {
    //     this.showUp = false;
    //   }
    //   this.lastScroll = window.scrollY;
    // });
    this.checkProduct();
  },
  methods: {
    showNotFoundMessage() {
      this.$swal({
        title: "No disponible",
        text: `Lo que buscabas no fue encontrado`,
        closeOnClickOutside: false,
        buttons: {
          cancel: "Salir",
          confirm: "Ir a tienda demo",
        },
      }).then((value) => {
        console.log("value", value);
        if (value == null) window.close();
        else {
          document.location.href = "https://pedidos.ticketapp.cl/demo";
        }
      });
    },
    sendRecover() {
      if (this.emailIsValid) return;
      this.$http
        .post("account/recover", { email: this.email })
        .then(() => {
          this.$bvToast.toast("Se ha enviado un correo con las instrucciones", {
            title: "Recuperar cuenta",
            variant: "success",
            solid: true,
          });
          this.$refs["modal-recover"].hide();
        })
        .catch(() => {
          this.$bvToast.toast("No se ha podido enviar el correo", {
            title: "Recuperar cuenta",
            variant: "danger",
            solid: true,
          });
        });
    },
    goHome() {
      this.$router.push(this.getLink(""));
    },
    checkProduct() {
      if (!this.info._id) return console.log("No info");
      let pid = this.$route.query.pid;
      if (pid) {
        this.pid = pid;
        this.$refs.modal.show();
      } else {
        this.$refs.modal.hide();
        this.setTitle();
      }
    },
    setTitle() {
      if (this.info.name)
        document.title = this.info.custom_name || this.info.name;
    },
    closeModal() {
      console.log("close modal");
      this.$router.push({ query: {} });
    },
    updateProductData(data) {
      console.log("update product data");
      this.productName = data;
    },
    viewProduct(pid, pname = "") {
      //const { info } = this.$store.state;
      //this.$router.push(`/${info.webName}/p/${pid}`);
      this.pid = pid;
      if (pname) this.productName = pname;
      this.$refs.modal.show();

      this.$gtag.event("product_view", {
        event_category: "product",
        event_label: "User view product",
        value: 1,
      });
      if (this.settings.facebookPixelEnabled && this.$analytics) {
        console.log("track view content");
        this.$analytics.fbq("track", "ViewContent", {
          pid: this.pid,
          content_name: pname,
        });
      }
    },
    logout() {
      this.$cookies.remove("token");
      this.$cookies.remove("clientName");
      this.$cookies.remove("phone");
      this.$cookies.remove("email");
      this.$router.push(this.getLink(""));
    },
    createAccount() {
      this.loading = true;
      this.$http
        .post(`account`, {
          name: this.clientName,
          email: this.email,
          password: this.password,
          phone: this.phone,
        })
        .then(() => {
          this.password = "";
          this.repassword = "";
          this.$bvToast.toast(
            "Se envio un correo de confirmacion a tu correo electronico",
            {
              title: "Cuenta creada",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch(({ data }) => {
          const { details } = data;
          this.$bvToast.toast(
            details || "Ocurrio un error al crear la cuenta",
            {
              title: "Error",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    login() {
      this.loading = true;
      this.$http
        .post(`account/auth`, {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.password = "";
          this.$cookies.set("token", res.data.data.token);
          this.$cookies.set("email", res.data.data.email);
          this.$cookies.set("clientName", res.data.data.name);
          this.$cookies.set("phone", res.data.data.phone);
          this.$router.push(this.getLink("cuenta"));
        })
        .catch(({ data }) => {
          const { details } = data;
          console.log({ details });
          this.$bvToast.toast(details || "Usuario o contraseña incorrecto", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalRecover() {
      this.$refs["modal-recover"].show();
    },
    showModalCreate() {
      this.$refs["modal-create"].show();
    },
    diffDay(day) {
      return this.$moment(day.openAt, "HH:mm:ss").diff(
        this.$moment(),
        "minutes"
      );
    },
    showSchedule() {
      this.$refs["schedule-modal"].show();
    },
    goWhatsApp() {
      let msg = `Hola me estoy comunicando desde la pagina web`;
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          this.settings.whatsapp +
          "&text=" +
          encodeURIComponent(msg),
        "_blank"
      );
    },
    getWaitTime() {
      return this.settings.waitTime.slice(0, 10);
    },
    openAddres() {
      this.openInMaps(`${this.info.address} - ${this.info.city}`);
    },
    goUp() {
      this.$store.commit("setLastscroll", Date.now());
      window.scroll(0, 0);
      this.$gtag.event("goup_click", {
        event_category: "user",
        event_label: "go to top bottom",
        value: 1,
      });
    },
    load() {
      this.attempt = this.attempt + 1;

      clearTimeout(this.timeout);
      //console.log("route", this.$route);
      const { name } = this.$route.params;
      const domain = document.location.host.replace(":", "");
      const firstSlug = document.location.pathname.split("/")[0];
      console.log({ domain, name, firstSlug });
      if (
        domain.indexOf(".ticketapp.cl") == -1 &&
        domain.indexOf("localhost") == -1 &&
        domain.indexOf("192.168") == -1 &&
        domain.indexOf("10.0") == -1
      ) {
        console.log("Domain", domain);
        this.$store.commit("setDomainTrue");
      }

      let bs = name;
      if (this.$store.state.isDomain) bs = domain;
      console.log("bs", bs);
      if (bs == undefined) {
        //bs = 'demo'
        if (this.attempt > 3) {
          return this.showNotFoundMessage();
        }
        return (this.timeout = setTimeout(this.load, 2000));
      }

      this.$http
        .get(`${bs}`)
        .then((res) => {
          if (!res.data.data._id) {
            throw "not_found";
          } else this.$store.commit("setInfo", res.data.data);
        })
        .catch((err) => {
          if (err.status == 404) {
            this.showNotFoundMessage();
          }
        });
      // }

      this.timeout = setTimeout(this.load, 360000);
    },
  },
  computed: {
    emailIsValid() {
      const email = this.email;
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(String(email).toLowerCase());
    },
    settings() {
      return this.info && this.info.settings ? this.info.settings : {};
    },
    info() {
      return this.$store.state.info;
    },
    products() {
      let p = sessionStorage.getItem("products");
      if (p) return JSON.parse(p);
      else return [];
    },
    total() {
      return this.$store.state.products.reduce((t, a) => t + a.total, 0);
    },
  },
  watch: {
    $route: function () {
      console.log("change route", this.$route);
      if (!this.info || !this.info._id) return this.load();
      this.checkProduct();
    },
    info() {
      this.setTitle();
      this.checkProduct();
      if (!this.appInit) {
        let $logo = document.querySelector("#logo");
        $logo.innerHTML = `<img src="${this.getLogo(
          this.info.logo
        )}" class="img-fluid px-1 animate__animated animate__flipInX"
          style="max-height: 50vh; width: 300px" alt="logo-loading" />`;

        console.log({ settings: this.settings });

        var link = document.querySelector("link[rel~='icon']");
        let head = document.querySelector("head");
        if (!link) {
          link = document.createElement("link");

          link.rel = "icon";
          head.appendChild(link);
          link.href = this.getLogo(this.info.logo);
        }

        if (this.settings.customFavicon) {
          link.href = this.settings.customFavicon;
        }

        if (this.settings.description) {
          // find meta og:description
          let meta = document.querySelector("meta[property='og:description']");
          if (!meta) {
            meta = document.createElement("meta");
            meta.property = "og:description";
            head.appendChild(meta);
          }
          meta.content = this.settings.description;
        }

        if (this.settings.customCss) {
          console.log("cargando custom css");
          if (document.querySelector("#custom-css")) {
            document.querySelector("#custom-css").remove();
          }
          let css = document.createElement("style");
          css.type = "text/css";
          css.id = "custom-css";
          css.innerHTML = JSON.parse(this.settings.customCss);
          head.appendChild(css);
        }

        if (this.info && this.info.settings.facebookPixelEnabled) {
          console.log("Pixel Enabled");
          this.$analytics.fbq.init(this.info.settings.facebookPixelCode, {
            em: this.info.settings.facebookPixelEmail,
          });
        }

        setTimeout(() => {
          document
            .querySelector("#loading")
            .classList.add("animate__fadeOutUp");
          this.appInit = true;
          this.show = true;
        }, 500);
      }
    },
  },
};
</script>

<style>
.custom_scroll {
  overflow-y: scroll;
  padding-right: 5px;
}
.custom_scroll::-webkit-scrollbar {
  width: 8px;
}
.custom_scroll::-webkit-scrollbar-track {
  margin: 0 0px;
}
.custom_scroll::-webkit-scrollbar-thumb {
  background: rgba(213, 215, 217, 0.521);
  border-radius: 10px;
  padding: 0 3px;
}
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(213, 215, 217, 0.221);
  border-radius: 10px;
  padding: 0 3px;
}
.custom_scrollX::-webkit-scrollbar-track {
  margin: 0 0px;
}
.fs-3 {
  font-size: 1.75rem;
}
#img-header {
  margin-left: -1px;
}
.btn {
  white-space: pre-wrap !important;
}
.modal-dialog {
  width: 98%;
}
.categories {
  overflow-x: hidden;
}
.categories:hover {
  overflow-x: scroll;
}
.bg-pink {
  background-color: #fb6499;
}
.bg-dark .card {
  background: rgba(2, 2, 2, 0.323) !important;
}
.bg-dark .border-bottom {
  border-bottom: 1px solid #dee2e656 !important;
}
.bg-dark .list-group-item {
  background: rgba(237, 237, 237, 0.121) !important;
  color: white;
}
.click {
  cursor: pointer;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}
.loading {
  position: fixed;
  width: 100%;
  background: #f0f0f0;
  z-index: 999;
  flex: 1;
}
.loading .row {
  height: 100vh;
}
.ql-align-center {
  text-align: center;
}
.badge-notif {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 1;
}
img {
  max-width: 100% !important;
}
.bg-img-1 {
  background: url("/images/food.webp");
}
.bg-img-2 {
  background: url("/images/beanstalk.webp");
}
.bg-img-3 {
  background: url("/images/email-pattern.webp");
}
.bg-img-4 {
  background: url("/images/double-bubble-dark.png");
}
.bg-img-5 {
  background: url("/images/memphis-mini.webp");
}
.bg-img-lined_paper {
  background: url("/images/lined_paper.png");
}
.bg-img-green_cup {
  background: url("/images/green_cup.png");
}
.bg-img-hip-square {
  background: url("/images/hip-square.png");
}
.bg-img-christmas {
  background: url("/images/christmas-colour.png");
}
.bg-img-christmas-black {
  background: url("/images/christmas-black.png");
}
</style>
